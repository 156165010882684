<template>
  <div>首页</div>
</template>

<script>
export default {
  name: 'home'
}
</script>

<style lang="scss" scoped></style>
